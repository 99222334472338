<template>
  <div>
    <div class="banner--part">
      <div class="title--text">
        {{ $t('home.officialPartner.title') }}
      </div>
      <img src="@/assets/img/home/banner-2.svg" class="banner--image" />
    </div>
    <div class="subtitle--text">
      {{ $t('home.officialPartner.benefit') }}
    </div>
    <div class="d-flex flex-wrap">
      <div class="col-12 col-md-4 benefit--col">
        <v-card>
          <div class="d-flex flex-wrap justify-center">
            <img src="@/assets/img/icons/landing-page.png" />
            {{ $t('home.officialPartner.benefit1') }}
          </div>
        </v-card>
      </div>
      <div class="col-12 col-md-4 benefit--col">
        <v-card>
          <div class="d-flex flex-wrap justify-center">
            <img src="@/assets/img/icons/form.png" />
            {{ $t('home.officialPartner.benefit2') }}
          </div>
        </v-card>
      </div>
      <div class="col-12 col-md-4 benefit--col">
        <v-card>
          <div class="d-flex flex-wrap justify-center">
            <img src="@/assets/img/icons/network.png" />
            {{ $t('home.officialPartner.benefit3') }}
          </div>
        </v-card>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'RentfixOpenApiContent',
  components: {},
};
</script>
