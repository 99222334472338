<template>
  <section class="help--desk-wrapper">
    <official-partner-content />
    <div class="title--text" id="officialPartnerForm">
      {{ $t('home.officialPartner.formTitle') }}
    </div>
    <official-partner-form />
  </section>
</template>

<script>
import OfficialPartnerContent from '@/components/help-desk/official-partner/official-partner-content';
import OfficialPartnerForm from '@/components/help-desk/official-partner/official-partner-form';

export default {
  components: {
    OfficialPartnerContent,
    OfficialPartnerForm,
  },
};
</script>
