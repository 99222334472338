<template>
  <div>
    <page-loading :show="loading" />
    <form @submit.prevent="submitRequest">
      <my-alert
        :variant="messageType"
        :show="messageShow"
        :dismissible="true"
        @dismissed="closeAlert"
      >
        <div v-html="messageAlert"></div>
      </my-alert>
      <div class="subtitle--text">
        {{ $t('home.rentfixOpenApi.pic') }}
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
        <div class="left--col">
          <label for="name">
            <span>{{ $t('home.rentfixOpenApi.name') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="name"
            name="name"
            type="text"
            :placeholder="$t('home.rentfixOpenApi.placeholder.name')"
            v-model="name"
          />
          <span class="val-error" v-if="validation.hasError('name')">{{
            validation.firstError('name')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
        <div class="left--col">
          <label for="phone">
            <span>{{ $t('home.rentfixOpenApi.phone') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            id="phone"
            v-model="phone"
            :options="phoneCleaveOption"
            class="basic--input"
            type="text"
            name="phoneNumber"
            :placeholder="$t('home.rentfixOpenApi.placeholder.phone')"
          />
          <span class="val-error" v-if="validation.hasError('phone')">{{
            validation.firstError('phone')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
        <div class="left--col">
          <label for="email">
            <span>{{ $t('home.rentfixOpenApi.email') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            name="email"
            id="email"
            type="email"
            :placeholder="$t('home.rentfixOpenApi.placeholder.email')"
            v-model="emailInput"
          />
          <span class="val-error" v-if="validation.hasError('email')">{{
            validation.firstError('email')
          }}</span>
        </div>
      </div>
      <div class="subtitle--text">
        {{ $t('home.rentfixOpenApi.company') }}
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('company') }">
        <div class="left--col">
          <label for="company">
            <span>{{ $t('home.officialPartner.companyName') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="company"
            name="company"
            type="text"
            :placeholder="$t('home.officialPartner.placeholder.companyName')"
            v-model="company"
          />
          <span class="val-error" v-if="validation.hasError('company')">{{
            validation.firstError('company')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('profile_file') }">
        <div class="left--col">
          <label for="profile-file">
            <span>{{ $t('home.rentfixOpenApi.profile') }}</span>
          </label>
        </div>
        <div class="right--col">
          <button
            class="btn btn-primary--outline btn--upload-profile"
            @click="onPickFile"
            type="button"
          >
            {{ $t('home.rentfixOpenApi.uploadProfile') }}
          </button>
          <div class="d-flex align-center" v-if="profile_file">
            <div class="col">Uploaded file: {{ profile_file.name }}</div>
            <img
              src="@/assets/img/icons/trash.svg"
              @click="removeFile"
              class="trash--button col-auto"
            />
          </div>
          <input
            id="profile-file"
            type="file"
            @change="onFileChange"
            accept=".pdf"
            ref="profile_file_ref"
            style="display: none"
          />
          <span class="val-error" v-if="validation.hasError('profile_file')">{{
            validation.firstError('profile_file')
          }}</span>
        </div>
      </div>
      <div class="d-flex justify-center">
        <button type="submit" class="btn btn-primary">{{ $t('home.coRenting.send_form') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const PageLoading = () => import('@/components/content-loading/page-loading');

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
// import VueRecaptcha from 'vue-recaptcha';
import { Validator } from 'simple-vue-validator';
import MyAlert from '@/components/utils/my-alert.vue';
export default {
  name: 'OfficialPartnerForm',
  mixins: [HelperMixin],
  components: {
    MyAlert,
    Cleave,
    PageLoading,
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      messageAlert: (state) => state.global.message,
      messageType: (state) => state.global.messageType,
      property_types: (state) => state.v2.masters.propertyTypes,
      listingType: (state) => state.v2.search.listingType,
    }),
    emailInput: {
      get() {
        return this.email;
      },
      set(val) {
        this.email = this.cleanEmail(val);
      },
    },
  },
  data: () => ({
    loading: false,
    name: '',
    phone: '',
    email: '',
    company: '',
    profile_file: '',
    messageShow: false,
    phoneCleaveOption: {
      phone: true,
      phoneRegionCode: 'id',
    },
  }),
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.name.required'));
    },
    phone(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.phone.required'))
        .maxLength(191, this.$i18n.t('errors.phone.max', { maxChar: 191 }));
    },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.email.max', { maxChar: 255 }));
    },
    company(value) {
      return Validator.value(value).required(this.$i18n.t('errors.companyName.required'));
    },
    // profile_file(value) {
    //   return Validator.value(value).required(this.$i18n.t('errors.uploadCompanyProfile.required'));
    // },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch('v2/masters/getPropertyTypes', { listingType: this.listingType });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onPickFile() {
      this.$refs.profile_file_ref.click();
    },
    removeFile() {
      this.profile_file = null;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.profile_file = files[0];
    },
    async submitRequest() {
      try {
        this.loading = true;

        const isValid = await this.$validate();
        if (isValid) {
          let formData = new FormData();
          formData.append('pic_name', this.name);
          formData.append('pic_phone', this.phone);
          formData.append('pic_email', this.email);
          formData.append('company_name', this.company);
          if (this.profile_file) {
            formData.append('company_profile_file', this.profile_file);
          }
          try {
            let response = await this.$store.dispatch('global/officialPartner', formData);
            if (response && response.type === 'success') {
              await this.$swal(
                this.$t('general.success'),
                'Terima Kasih telah menghubungi kami. Kami akan segera menghubungi Anda kembali.',
                'success',
              );
              this.resetForm();
            }
          } catch (err) {
            console.log('Error request official partner: ', err);
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    closeAlert() {
      this.$store.commit('global/RESET_ALERT');
    },
    resetForm() {
      this.name = null;
      this.phone = null;
      this.email = null;
      this.company = null;
      this.profile_file = null;
      this.validation.reset();
    },
  },
};
</script>
